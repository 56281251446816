<template>
  <section :class="['container', {'ocultar-overflow-y': siMuestraRutaHija}]">
    <article class="pt-3">
      <div class="vista-encabezado">
        <header class="col titulo h4-responsive text-center text-md-left">
          Filtros de búsqueda
        </header>
      </div>
      <div class="row">
        <FiltrosTipo1
          :zonas="zonasFiltro"
          :tipos-cli="tiposFiltro"
          :conDePago="condicionesPagoSelect"
          :tipPrecio="tipoPrecio"
          :ven="vendedoresFiltro"
          facturas
          fecha
          nombre
          cli-potenciales
          @zonasAFiltrar="aplicarFiltrosZonas($event.estados, $event.municipios, $event.parroquias)"
          @tiposClienteAFiltrar="tiposAFiltrar=$event; aplicarFiltros()"
          @vendedorAFiltrar="vendedorAFiltrar=$event; aplicarFiltros(); sucCam = []"
          @nombreAFiltrar="nombreAFiltrar=$event; aplicarFiltros()"
          @condiPagoAFiltrar="condiPagoAFiltrar=$event; aplicarFiltros()"
          @tipoPrecioAFiltrar="tipoPrecioFiltrar=$event; aplicarFiltros()"
          @estatusFacturaAFiltrar="estatusFacturaAFiltrar=$event; aplicarFiltros()"
          @fechaAFiltrar="fechaAFiltrar=$event; aplicarFiltros()"
          @clientesPo="clientesPo=$event; aplicarFiltros()"
          @eliminarFiltros="eliminarFiltrosSeleccionados"
        />
      </div>
      <!-- Botón para cambiar vendedor a cliente/s  -->
      <transition name="botones-opcionales-transition">
        <div
          v-if="sucCam.length"
          class="botones-opcionales"
        >
          <mdb-btn
            color="secundario"
            icon="user-edit"
            @click="editarVen = true"
          >
            Cambiar vendedor
          </mdb-btn>
        </div>
      </transition>
      <div
        v-if="cargando"
        class="mensaje-no-items py-3"
      >
        <LoaderKel />
        <p class="texto mt-2">
          Cargando
        </p>
      </div>
      <ul
        v-else-if="Object.keys(clientes).length"
        class="table t-clientes"
      >
        <li class="encabezado sticky">
          <div class="row align-items-center mx-0">
            <div class="col-12 col-md px-0 text-center text-sm-left">
              <header class="h4-responsive">Clientes</header>
              <p class="descripcion text-capitalize">En lista: {{Object.keys(clientes).length}} empresas, {{totalSucursales}} sucursales</p>
            </div>
            <div
              v-if="vendedorAFiltrar.length == 1"
              class="col-12 col-md-auto"
            >
              <div class="custom-control custom-switch secundario py-2 mb-2 mb-md-0">
                <input
                  id="customSwitches-seleccionar-clientes"
                  type="checkbox"
                  class="custom-control-input secundario"
                  title="Seleccionar todos los clientes"
                  @click="seleccionarTodos($event.target.checked)"
                >
                <label
                  class="custom-control-label"
                  for="customSwitches-seleccionar-clientes"
                >
                  Seleccionar todos los clientes
                </label>
              </div>
            </div>
          </div>
          <div class="thead claro">
            <div class="col">Empresa</div>
            <div class="col">Tipos</div>
            <div class="col">Estatus de factura</div>
            <div class="col">Zonas</div>
            <div class="col">Condición de pago</div>
            <div class="col">Tipo de precio</div>
          </div>
        </li>
        <router-link
          :to="{name: 'Cliente', params: {id: empresa.id_emp}}"
          custom
          v-slot="{navigate}"
          v-for="empresa in clientes"
          :key="empresa.id_emp"
          class="contenido cursor-pointer"
        >
          <li @click="navigate">
            <div class="col" data-columna="Empresa">
              <p
                v-if="vendedorAFiltrar.length == 1"
                class="text-capitalize w-100"
                @click.stop
              >
                <label class="checkbox secundario w-100 my-sm-n2 pb-2 pt-sm-2">
                  <input
                    type="checkbox"
                    name="checkbox-clientes"
                    v-model="sucCam"
                    :value="empresa.suc"
                    @click="vendedorAct = vendedorAFiltrar[0]"
                  >
                  <span class="icono mr-1" />
                  {{ empresa.nom_emp }}
                </label>
              </p>
              <p
                v-else
                class="text-capitalize"
              >
                {{ empresa.nom_emp }}
              </p>
            </div>
            <div class="col" data-columna="Tipos">
              <p>
                <span v-for="tipo in empresa.tipos" :key="tipo" class="auto-coma">
                  <span v-if="tipos[tipo] ">
                    {{ tipos[tipo] && tipos[tipo].nom_tcl ? tipos[tipo].nom_tcl  : ''}}
                  </span>
                  <span v-else class="text-muted font-italic">
                    Sin tipo especificado
                  </span>
                </span>
              </p>
            </div>
            <div class="col" data-columna="Estatus de factura">
              <p :class="[asignarColorEstado(empresa.est_fac), 'text-capitalize font-weight-bold']">
                {{ empresa.est_fac }}
                <small v-if="empresa.fec_ven_fac && empresa.est_fac !== 'Solvente'" :class="[diasEstatusFac(empresa.fec_ven_fac).color, 'font-weight-bold d-block']"
                >
                  {{diasEstatusFac(empresa.fec_ven_fac).dias}} días
                </small>
              </p>
            </div>
            <div class="col" data-columna="Zonas">
              <p>
                <span v-for="zona in empresa.zonas" :key="zona">
                  <span v-if="zonas[zona]">
                    {{ zonas[zona].est_zon ? zonas[zona].est_zon  : 'Sin estado'}}, {{ zonas[zona].nom_zon  }}
                    {{ zonas[zona].sec_zon ? zonas[zona].sec_zon : 'Sin sector' }}.
                  </span>
                  <span v-else class="text-muted font-italic">
                    Sin zona especificada
                  </span>
                </span>
              </p>
            </div>
            <div class="col" data-columna="Condición de pago">
              <p>
                <span v-for="cp in empresa.cp" :key="cp">
                  <span v-if="conDePa[cp]">
                    {{ conDePa[cp].nom_cp ? conDePa[cp].nom_cp  : 'Sin condición de pago'}}.
                  </span>
                  <span v-else class="text-muted font-italic">
                    Sin condición de pago especificada
                  </span>
                </span>
              </p>
            </div>
            <div class="col" data-columna="Tipo de precio">
              <p>
                <span v-for="tpre in empresa.tpre" :key="tpre">
                  <span v-if="tiposPrecio[tpre]">
                    {{ tiposPrecio[tpre].nom_tpre ? tiposPrecio[tpre].nom_tpre  : 'Sin tipo de precio'}}.
                  </span>
                  <span v-else class="text-muted font-italic">
                    Sin tipo de precio especificado
                  </span>
                </span>
              </p>
            </div>
          </li>
        </router-link>
      </ul>
      <div
        v-else-if="!Object.keys(clientes).length && filtrosActivos"
        class="mensaje-no-items py-5"
      >
        <span class="fa-stack icono position-relative ml-n4 mb-4">
          <font-awesome-icon
            icon="search"
            size="5x"
            class="fa-stack-2x"
          />
          <font-awesome-icon
            icon="times"
            size="2x"
            class="fa-stack-1x"
            style="position: absolute;top: -10px;left: 14px;"
          />
        </span>
        <p class="texto text-center">
          No se encontraron empresas
          <span class="d-block">durante la búsqueda</span>
        </p>
      </div>
      <div
        v-else
        class="mensaje-no-items py-5"
      >
        <font-awesome-icon
          icon="search"
          size="5x"
          class="icono"
        />
        <p class="texto text-center">
          Consulta los clientes a través de
          <span class="d-block">los filtros para continuar</span>
        </p>
      </div>
      <ConfirmacionEliminar
        :boton-deshabilitado="botonDeshabilitado"
        :mostrar-modal="modalConfirmacionEliminar"
        mensaje="Esta acción cambiará el vendedor de los clientes seleccionados"
        iconoBotonConfirmar="user-check"
        textoBotonConfirmar="Cambiar"
        @confirmar="camVen()"
        @cerrar="modalConfirmacionEliminar = false; vendedorNu = ''"
      />
      <mdb-modal
        centered
        elegant
        scrollable
        :show="editarVen"
        @close="editarVen = false; vendedorNu = ''"
      >
        <mdb-modal-header>
          <mdb-modal-title>
            Asignación de vendedor
          </mdb-modal-title>
        </mdb-modal-header>
        <mdb-modal-body>
          <form
            class="text-center"
            @submit.prevent="editarVen = false; modalConfirmacionEliminar = true"
          >
            <div
              class="md-form md-outline outline-select mt-0 mb-2 solo-lectura"
              title="El nombre del vendedor actual es de solo lectura"
            >
              <select
                v-model="vendedorAct"
                class="custom-select"
              >
                <option class="d-none" disabled value="">Seleccione</option>
                <option
                  v-for="v in vendedoresFiltro"
                  :key="v.id_per"
                  :value="v.id_per"
                >
                  {{v.nom_per}} {{v.ape_per}}
                </option>
              </select>
              <label
                :class="vendedorAct.length
                  ? 'label-active'
                  : 'label-inactive'"
              >
                Vendedor actual
              </label>
            </div>
            <div class="md-form md-outline outline-select mt-2 mb-3">
              <select
              :required="true"
                v-model="vendedorNu"
                class="custom-select"
              >
                <option class="d-none" disabled value="">Seleccione</option>
                <option
                  v-for="v in vendedoresDisponiblesParaAsignar"
                  :key="'v'+v.id_per"
                  :value="v.id_per"
                >
                  {{v.nom_per}} {{v.ape_per}}
                </option>
              </select>
              <label
                :class="vendedorNu.length
                  ? 'label-active'
                  : 'label-inactive'"
              >
                Vendedor a asignar
              </label>
            </div>
            <mdb-btn
              title="Cambiar vendedor"
              color="primario"
              icon="check"
              type="submit"
              class="mt-4"
            >
              Cambiar vendedor
            </mdb-btn>
          </form>
        </mdb-modal-body>
      </mdb-modal>
    </article>
    <transition
      name="custom-classes-transition"
      enter-active-class="animated fadeInRight"
      leave-active-class="animated fadeOutRight"
    >
      <router-view />
    </transition>
    <AlertaMensaje
      :alerta-mensaje="alertaMensaje"
      @cerrar="alertaMensaje.contenido = ''"
    />
  </section>
</template>

<script>
// @ is an alias to /src
import FiltrosTipo1 from '@/components/FiltrosTipo1.vue'
import { apiPost } from '@/funciones/api.js'
import {
  mdbBtn,
  mdbModal,
  mdbModalHeader,
  mdbModalTitle,
  mdbModalBody
} from 'mdbvue'
import ConfirmacionEliminar from '@/components/ConfirmacionEliminar.vue'
import { asignarColorEstado, comprobarRutaHija, formatearFecha } from '@/funciones/funciones.js'
import AlertaMensaje from '@/components/AlertaMensaje.vue'
import LoaderKel from '@/components/LoaderKel.vue'

export default {
  name: 'Clientes',
  components: {
    FiltrosTipo1,
    mdbBtn,
    mdbModal,
    mdbModalHeader,
    mdbModalTitle,
    mdbModalBody,
    ConfirmacionEliminar,
    AlertaMensaje,
    LoaderKel
  },
  data () {
    return {
      asignarColorEstado,
      titulo: 'Estados',
      alertaMensaje: { contenido: '' },
      totalSucursales: 0,
      zonas: {},
      zonasFiltro: [],
      tipos: {},
      tiposFiltro: [],
      clientes: {},
      vendedoresFiltro: [],
      vendedorAFiltrar: [],
      tiposAFiltrar: [],
      nombreAFiltrar: '',
      fechaAFiltrar: '',
      estadosAFiltrar: [],
      municipiosAFiltrar: [],
      parroquiasAFiltrar: [],
      estadoSeleccionado: '',
      estatusFacturaAFiltrar: [],
      municipioSeleccionado: '',
      botonDeshabilitado: false,
      modalConfirmacionEliminar: false,
      editarVen: false,
      vendedorAct: '',
      vendedorNu: '',
      sucCam: [],
      cargando: false,
      // filtro de busqueda ultimos pedidos
      condicionesPagoSelect: [],
      condiPagoAFiltrar: [],
      conDePa: {},
      tipoPrecio: [],
      tipoPrecioFiltrar: [],
      tiposPrecio: [],
      clientesPo: false,
      timeout: null,
      fechaActual: new Date().getFullYear() + '-' + ('0' + (new Date().getMonth() + 1)).substr(-2) + '-' + ('0' + new Date().getDate()).substr(-2)
    }
  },
  methods: {
    // Debe traer las zonas, los vendedores y los tipos de clientes.
    // También los primeros 50 clientes por orden alfabético, los demás los trae al hacer scroll (si
    // no se ha seleccionado otro filtro)
    cargaInicial () {
      this.resultados = {}
      apiPost({ s: 'clientes_generalMv' }, '')
        .then((res) => {
          this.enlistarZonas(res.data.zon)
          this.zonasFiltro = res.data.zon
          this.enlistarTipos(res.data.tcl)
          this.enlistarConDePa(res.data.cp)
          this.enlistarTiPre(res.data.tpre)
          this.tiposFiltro = res.data.tcl
          this.vendedoresFiltro = res.data.ven
          this.condicionesPagoSelect = res.data.cp
          this.tipoPrecio = res.data.tpre
        })
    },
    aplicarFiltros () {
      if (this.timeout) { clearTimeout(this.timeout) }
      this.timeout = setTimeout(() => {
        this.cargando = true
        apiPost({ s: 'clientes_filtradosMv' }, 'tipos=' + this.tiposAFiltrar + '&vendedores=' + this.vendedorAFiltrar + '&nombre=' +
        this.nombreAFiltrar + '&fecha_desde=' + this.fechaAFiltrar + '&estados=' + this.estadosAFiltrar + '&municipios=' +
        this.municipiosAFiltrar + '&parroquias=' + this.parroquiasAFiltrar + '&condicion=' + this.condiPagoAFiltrar + '&precio=' +
        this.tipoPrecioFiltrar + '&factura=' + this.estatusFacturaAFiltrar + '&clip=' + this.clientesPo)
          .then((res) => {
            this.enlistarEmpresas(res.data.emp)
            this.totalSucursales = res.data.emp.length
          })
      }, 500) // delay
    },
    aplicarFiltrosZonas (estadosFil, municipiosFil, parroquiasFil) {
      this.estadosAFiltrar = []
      this.municipiosAFiltrar = []
      this.parroquiasAFiltrar = []

      estadosFil.forEach(e => {
        this.estadosAFiltrar.push(e.est_zon)
      })
      municipiosFil.forEach(m => {
        this.municipiosAFiltrar.push(m.ciu_zon)
      })
      parroquiasFil.forEach(p => {
        this.parroquiasAFiltrar.push(p.sec_zon)
      })

      this.aplicarFiltros()
    },
    eliminarFiltrosSeleccionados () {
      this.vendedorAFiltrar = []
      this.tiposAFiltrar = []
      this.nombreAFiltrar = ''
      this.fechaAFiltrar = ''
      this.estadosAFiltrar = []
      this.municipiosAFiltrar = []
      this.parroquiasAFiltrar = []
      this.estatusFacturaAFiltrar = []
      this.clientes = {}
      this.totalSucursales = 0
      this.sucCam = []
      this.condiPagoAFiltrar = []
      this.tipoPrecioFiltrar = []
    },
    enlistarConDePa (data) {
      const conDePa = {}
      data.forEach(cp => {
        if (!conDePa[cp.id_cp]) {
          conDePa[cp.id_cp] = {
            id_cp: cp.id_per_cp,
            nom_cp: cp.nom_cp
          }
        }
      })
      this.conDePa = conDePa
    },
    enlistarEmpresas (data) {
      const empresas = {}
      data.forEach(e => {
        if (!empresas[`${e.nom_emp}-${e.id_emp}`]) {
          empresas[`${e.nom_emp}-${e.id_emp}`] = {
            id_emp: e.id_emp,
            nom_emp: e.nom_emp,
            rif_emp: e.rif_emp,
            id_tcl_suc: e.id_tcl_suc,
            id_zon_suc: e.id_zon_suc,
            est_fac: e.est_fac,
            fec_ven_fac: e.fec_ven_fac,
            tipos: {},
            zonas: {},
            cp: {},
            tpre: {},
            suc: []
          }
        }
        empresas[`${e.nom_emp}-${e.id_emp}`].tipos[e.id_tcl_suc] = e.id_tcl_suc
        empresas[`${e.nom_emp}-${e.id_emp}`].zonas[e.id_zon_suc] = e.id_zon_suc
        empresas[`${e.nom_emp}-${e.id_emp}`].cp[e.id_cp_ped] = e.id_cp_ped
        empresas[`${e.nom_emp}-${e.id_emp}`].tpre[e.id_tpre_ped] = e.id_tpre_ped
        empresas[`${e.nom_emp}-${e.id_emp}`].suc.push(e.id_suc)
      })
      this.clientes = empresas
      this.cargando = false
    },
    enlistarZonas (data) {
      const zonas = {}
      data.forEach(e => {
        if (!zonas[e.id_zon]) {
          zonas[e.id_zon] = {
            id_zon: e.id_zon,
            est_zon: e.est_zon,
            ciu_zon: e.ciu_zon,
            sec_zon: e.sec_zon
          }
        }
      })
      this.zonas = zonas
    },
    enlistarTipos (data) {
      const tipos = {}
      data.forEach(e => {
        if (!tipos[e.id_tcl]) {
          tipos[e.id_tcl] = {
            id_tcl: e.id_tcl,
            nom_tcl: e.nom_tcl
          }
        }
      })
      this.tipos = tipos
    },
    enlistarTiPre (data) {
      const tiposPre = {}
      data.forEach(tpre => {
        if (!tiposPre[tpre.id_tctprel]) {
          tiposPre[tpre.id_tpre] = {
            id_tpre: tpre.id_tpre,
            nom_tpre: tpre.nom_tpre
          }
        }
      })
      this.tiposPrecio = tiposPre
    },
    camVen () {
      if (!this.vendedorNu.length) {
        this.alertaMensaje = {
          contenido: 'Debes seleccionar un vendedor para asignar',
          tipo: 'advertencia'
        }
        return
      }
      apiPost({ s: 'cliCamVenMv' }, 'suc=' + this.sucCam + '&ven=' + this.vendedorNu)
        .then((res) => {
          if (res.data.r) {
            this.alertaMensaje = {
              contenido: 'Operación exitosa',
              tipo: 'correcto'
            }
            this.modalConfirmacionEliminar = false
            this.vendedorNu = ''
            this.sucCam = []
            this.aplicarFiltros()
          } else {
            this.alertaMensaje = {
              contenido: 'Ha ocurrido un problema',
              tipo: 'error'
            }
          }
        })
    },
    seleccionarTodos (checked) {
      if (checked) {
        for (const c in this.clientes) {
          this.sucCam.push(this.clientes[c].suc)
        }
        this.vendedorAct = this.vendedorAFiltrar[0]
      } else {
        this.sucCam = []
      }
    },
    diasEstatusFac (ven) {
      let dif = 0
      let color = ''
      let dias = ''
      let fFecha2 = 0
      const venFac = formatearFecha(ven)
      const aFecha1 = venFac.split('-')
      const fFecha1 = Date.UTC(aFecha1[2], aFecha1[1] - 1, aFecha1[0])
      const actual = formatearFecha(this.fechaActual)
      const aFecha2 = actual.split('-')
      fFecha2 = Date.UTC(aFecha2[2], aFecha2[1] - 1, aFecha2[0])
      dif = fFecha2 - fFecha1
      const total = Math.floor(dif / (1000 * 60 * 60 * 24))
      if (total <= 0) {
        dias = 'Faltan ' + Math.abs(total)
        color = 'texto-exitoso'
      } else {
        dias = 'Pasaron ' + Math.abs(total)
        color = 'texto-error'
      }
      return { dias, color }
    }
  },
  computed: {
    filtrosActivos () {
      return (this.estadosAFiltrar.length || this.municipiosAFiltrar.length || this.parroquiasAFiltrar.length ||
        this.tiposAFiltrar.length || this.vendedorAFiltrar.length || this.nombreAFiltrar.length || this.fechaAFiltrar.length)
    },
    siMuestraRutaHija () {
      return comprobarRutaHija(this.$route.path.slice(1).split('/'))
    },
    vendedoresDisponiblesParaAsignar () {
      return this.vendedoresFiltro.filter(vendedor => vendedor.id_per !== this.vendedorAct) || this.vendedoresFiltro
    }
  },
  mounted () {
    this.cargaInicial()
  }
}
</script>

<style lang="scss" scoped>
.botones-opcionales {
  right: 1rem;

  @media screen and (min-width: 768px) {
    right: calc(50% - 109px);
  }
  @media screen and (min-width: 992px) {
    right: calc(50% - 205px);
  }
}

.table.t-clientes {
  margin: 2.5rem auto 6rem;

  .contenido > [class*=col] {line-height: 1.3}

  // Define los breakpoints para el responsive de ul.table
  $breakpointToChange: "768px";
  @include ul-table-responsive-at ($breakpointToChange);

  // Anchos específicos de columnas
  @media screen and (min-width: $breakpointToChange) {
    .acciones {width: 140px;}
  }
}
</style>
