<template>
<section>
  <!-- Tabla con flexbox de productos y clientes -->
  <form
    class="tabla-pedidos"
    @submit.prevent
  >
    <!-- Título y filtros -->
    <div
      v-if="mostrarEncabezado"
      class="encabezado-tabla row align-items-center justify-content-center mx-0 sticky-0"
    >
      <header
        v-if="titulo && titulo.length"
        class="col titulo no-print"
      >
        {{titulo}}
      </header>
      <header class="col titulo print-only">
        Nota de entrega
      </header>
      <div
        v-if="mostrarFiltrosCategoria"
        class="col-12 col-md-auto mb-sm-2 mb-md-0 py-2 px-0 px-sm-2 text-center btn-checkgroup-contenedor no-print"
      >
        <span
          v-for="cat in categorias"
          :key="'cat-'+cat.id_cat"
        >
          <input
            v-model="cateAMostrar"
            :id="`cat-${cat.id_cat}-${identificador}`"
            type="checkbox"
            name="checkbox-categoria"
            :value="cat.id_cat"
          >
          <label :for="`cat-${cat.id_cat}-${identificador}`" class="btn-checkgroup">
            {{cat.nom_cat}}
          </label>
        </span>
      </div>
    </div>

    <div class="tabla-contenedor">
      <!-- Tabla de datos -->
      <div class="tabla-distribucion">
        <div :class="['thead', {'pt-2': !mostrarEncabezado}]">
          <div class="columna-principal">
            <div class="filas row-nowrap no-print">
              <!-- Columna encabezado -->
              <header class="sticky-0 ancho-1 column-center encabezado bold">
                Día / Mes - Hora
                <small>Pedido {{mostrarFiltrosCategoria ? '- Zona' : ''}}</small>
              </header>

              <!-- Iteración de los datos de los pedidos -->
              <div
                v-for="ped in pedidos"
                :key="`fecha-${ped.id_ped}`"
              >
                <div
                  v-if="cargaPedido && cargaPedido[ped.id_ped]"
                  class="celda-checkbox"
                >
                  <p>
                    {{ formatearFechaYHora(ped.fec_cre_ped) }}
                    <small class="bold">
                      {{ped.id_ped}} {{mostrarFiltrosCategoria ? '- ' + ped.est_zon : ''}}
                      <span v-if="ped.obs_ped" class="float-right ml-1">
                        <mdb-tooltip
                          trigger="hover"
                          :options="{placement: 'top'}"
                        >
                          <span slot="tip" class="fs-initial">
                            <small class="bold">OBSERVACIÓN DEL PEDIDO</small>
                            {{ ped.obs_ped }}
                          </span>
                          <font-awesome-icon
                            icon="info-circle"
                            size="lg"
                            class="float-right"
                            slot="reference"
                          />
                        </mdb-tooltip>
                      </span>
                      <span v-if="nuevos.porPed[ped.id_ped]">
                        <mdb-tooltip
                          trigger="hover"
                          :options="{placement: 'top'}"
                        >
                          <span slot="tip" class="fs-initial">
                            <small class="bold">CLIENTE NUEVO</small>
                          </span>
                          <font-awesome-icon
                            icon="user-tie"
                            size="lg"
                            class="float-right"
                            slot="reference"
                          />
                        </mdb-tooltip>
                      </span>
                    </small>
                  </p>
                  <label class="checkbox">
                    <input
                      type="checkbox"
                      @change="$emit('todos', {pedido: ped.id_ped, checked: $event.target.checked, zona: identificador})"
                    >
                    <span class="icono-checkbox" />
                  </label>
                </div>
              </div>
            </div>
            <div class="filas row-nowrap">
              <header class="sticky-0 ancho-1 column-center encabezado bold">
                Razón social
                <small>
                  <span class="print-only">RIF</span>
                  Sucursal
                  <span class="no-print">, Código</span>
                </small>
              </header>

              <!-- Iteración de los datos de los pedidos -->
              <div
                v-for="ped in pedidos"
                :key="'s'+ped.id_ped"
                class="column-center text-capitalize text-break"
              >
              <template v-if="cargaPedido && cargaPedido[ped.id_ped]">
                {{ped.nom_emp}}
                <small>
                  <span class="print-only">{{ ped.rif_emp}}</span>
                  {{ped.nom_suc}}
                  <span class="no-print">{{ped.cod_suc ? ', ' + ped.cod_suc : ''}}</span>
                </small>
                <mdb-btn
                  v-if="permitirEliminar"
                  block
                  type="reset"
                  flat
                  dark-waves
                  size="sm"
                  icon="user-times"
                  class="p-1 no-print"
                  title="Eliminar cliente"
                  @click="$emit('eliminar', ped.id_ped)"
                >
                  Eliminar
                </mdb-btn>
              </template>
              </div>
            </div>
          </div>
          <div class="ancho-4 column-center encabezado text-right bold p-2 no-print">
            Cantidad a cargar
            <small>/ Cantidad pedida</small>
          </div>
          <div class="ancho-4 column-center encabezado text-right bold p-2 no-print">
            Peso a cargar
            <small>/ Peso pedido</small>
          </div>
        </div>
        <div class="tbody">
          <!-- Categoría -->
          <div
            v-for="cat in categorias"
            :key="'t'+cat.id_cat"
            :class="['row-nowrap', {'d-none': !categoriasAMostrar.includes(cat.id_cat)}]"
          >
            <template v-if="categoriasAMostrar.includes(cat.id_cat)">
              <!-- Nombre de categoría -->
              <div class="ancho-2 column-center encabezado bold sticky-0 p-0">
                <div class="rotar-titulo">
                  {{cat.nom_cat}}
                </div>
              </div>
              <!-- Producto -->
              <div class="productos">
                <div
                  v-for="pro in cat.productos"
                  :key="`prod-${pro.id_pro}`"
                  class="row-nowrap"
                >
                  <!-- Nombre de producto -->
                  <div class="column-center encabezado bold sticky-1 p-0">
                    <div class="ancho-2-2 ">
                      {{pro.nom_pro}}
                    </div>
                  </div>
                  <!-- Presentaciones -->
                  <div class="presentaciones">
                    <div
                      v-for="pre in pro.presentaciones"
                      :key="`pres-${pre.id_pre}`"
                      class="row-nowrap"
                    >
                      <!-- Nombre de presentación -->
                      <div class="ancho-3 sticky-2 encabezado px-1">
                        {{pre.des_pre}}
                        <small class="text-right bold no-print">
                          <span
                            v-if="totalPresentacionGeneral[pre.id_pre]"
                            class="texto-primario"
                            title="Inventario restante"
                          >
                            {{pre.can_pre - totalPresentacionGeneral[pre.id_pre]}}
                            <span class="text-muted">/</span>
                          </span>
                          <span title="Inventario disponible">{{pre.can_pre}}</span>
                        </small>
                        <mdb-btn
                          v-if="permitirEliminar"
                          block
                          type="reset"
                          flat
                          dark-waves
                          size="sm"
                          icon="trash"
                          class="p-1 no-print"
                          title="Eliminar presentación para todos los clientes"
                          @click="$emit('eliminarPresentacion', pre.id_pre)"
                        >
                          Eliminar
                        </mdb-btn>
                      </div>

                      <!-- Iteración de los datos de los pedidos -->
                      <div class="row-nowrap">
                        <div
                          v-for="ped in pedidos"
                          :key="`pedido-${ped.id_ped}`"
                          :class="[{'fila-error' : (parseInt(pre.can_pre) < (totalesPorPresentacionPedidos && totalesPorPresentacionPedidos[pre.id_pre] ? totalesPorPresentacionPedidos[pre.id_pre].cantidad : 0))},'pedido column-center']"
                        >
                          <div :class="[{'texto-error' : (parseInt(pre.can_pre) < (totalesPorPresentacionPedidos && totalesPorPresentacionPedidos[pre.id_pre] ? totalesPorPresentacionPedidos[pre.id_pre].cantidad : 0))}, 'celda-checkbox print-jc-center']">
                            <mdb-input
                              v-model="cargaPedido[ped.id_ped][pre.id_pre]"
                              @input="cargaPedido[ped.id_ped][pre.id_pre] = $event, $emit('actualizar')"
                              @change="cargaPedido[ped.id_ped][pre.id_pre] = $event, $emit('actualizar')"
                              type="number"
                              :min="0"
                              :max="maximoInv(cargaPedido[ped.id_ped][pre.id_pre], pre.id_pre, pre.can_pre)"
                              size="sm"
                              :placeholder="(ped.notas[pre.id_pre] && ped.notas[pre.id_pre].inventario
                                            ? 'inv: '+ped.notas[pre.id_pre].inventario : '')"
                              class="my-0 text-right no-print"
                              outline
                            />
                            <span class="mr-1 print-only">
                              {{cargaPedido[ped.id_ped][pre.id_pre] ? cargaPedido[ped.id_ped][pre.id_pre] : 0}}
                            </span>
                            <label class="checkbox no-print">
                              / {{(ped.notas[pre.id_pre] ? ped.notas[pre.id_pre].cantidad : 0)}}
                              <input
                                type="checkbox"
                                @click="$event.target.checked ? (ped.notas[pre.id_pre] ? cargaPedido[ped.id_ped][pre.id_pre] = ped.notas[pre.id_pre].cantidad : cargaPedido[ped.id_ped][pre.id_pre] = '') : cargaPedido[ped.id_ped][pre.id_pre] = ''; $emit('actualizar')"
                              >
                              <span class="icono-checkbox ml-1" />
                            </label>
                          </div>
                        </div>
                      </div>

                      <!-- Campos de totales a despachar y pedidos por presentación -->
                      <div class="pedido celda-numerica p-2 no-print">
                        <span title="Cantidad a cargar">
                          {{totalesPorPresentacion && totalesPorPresentacion[pre.id_pre] && totalesPorPresentacion[pre.id_pre].cantidad  ? convertirValorAFixed(totalesPorPresentacion[pre.id_pre].cantidad) : 0}} /
                        </span>
                        <span title="Cantidad pedida" class="font-weight-bold">
                          {{totalesPorPresentacionPedidos && totalesPorPresentacionPedidos[pre.id_pre] && totalesPorPresentacionPedidos[pre.id_pre].cantidad ? convertirValorAFixed(totalesPorPresentacionPedidos[pre.id_pre].cantidad) : 0}}
                        </span>
                      </div>
                      <div class="pedido celda-numerica p-2 no-print">
                        <span title="Peso a cargar">
                          {{totalesPorPresentacion && totalesPorPresentacion[pre.id_pre] && totalesPorPresentacion[pre.id_pre].peso ? convertirValorAFixed(totalesPorPresentacion[pre.id_pre].peso) : 0}} /
                        </span>
                        <span title="Peso pedido" class="font-weight-bold">
                          {{totalesPorPresentacionPedidos && totalesPorPresentacionPedidos[pre.id_pre] && totalesPorPresentacionPedidos[pre.id_pre].cantidad ? convertirValorAFixed(totalesPorPresentacionPedidos[pre.id_pre].peso) : 0}}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </div>
          <!-- Totales cliente -->
          <div
            class="row-nowrap bg-light-gray pt-2"
          >
            <div class="ancho-5 encabezado column-center bold p-0 ml-2 pl-1">
              <span>
                Total peso por pedido
              </span>
            </div>
            <!-- Presentaciones -->
            <div
              v-for="(totales, tot) in totalesPorPedidosPedido"
              :key="`prod-${tot}`"
              class="row-nowrap"
            >
              <div class="pedido column-center celda-numerica px-2 text-right">
                <span class="mr-3">
                  {{totalesPorPedido[tot] ? convertirValorAFixed(totalesPorPedido[tot].peso) : 0}} / {{convertirValorAFixed(totales.peso)}} kg
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Cantidad totales -->
    <div class="row align-items-center mx-0 bg-light-gray justify-content-end">
      <div class="col encabezado bold">
        Totales
      </div>
      <!-- Campos de totales a despachar y pedidos -->
      <div class="ancho-4 ancho-5-print column-center celda-numerica font-weight-bold p-2">
        <p class="mb-0">
          <span title="Cantidad total a cargar">
            {{totalesPorPresentacion && totalesPorPresentacion.cantidad ? convertirValorAFixed(totalesPorPresentacion.cantidad) : 0}}
          </span>
          <span title="Cantidad total pedida">
            / {{totalesPorPresentacionPedidos && totalesPorPresentacionPedidos.cantidad ? convertirValorAFixed(totalesPorPresentacionPedidos.cantidad) : 0 }}
          </span>
        </p>
        <span class="encabezado bold">Cantidad a cargar</span>
      </div>
      <div class="ancho-4 ancho-5-print column-center celda-numerica font-weight-bold p-2">
        <p class="mb-0">
          <span title="Peso total a cargar">
            {{totalesPorPresentacion.peso ? convertirValorAFixed(totalesPorPresentacion.peso) : 0}}
          </span>
          <span title="Peso total pedido">
            / {{totalesPorPresentacionPedidos && totalesPorPresentacionPedidos.peso ? convertirValorAFixed(totalesPorPresentacionPedidos.peso) : 0 }} kg
          </span>
        </p>
        <span class="encabezado bold">Peso a cargar</span>
      </div>
    </div>
    <!-- Botones de acciones en tabla -->
    <footer v-if="!ocultarBotonesAcciones" class="no-print">
      <mdb-btn
        type="reset"
        flat
        dark-waves
        icon="broom"
        class="px-3 py-2 order-sm-1"
      >
        Limpiar
      </mdb-btn>
      <mdb-btn
        flat
        dark-waves
        icon="print"
        class="px-3 py-2 order-sm-3"
        @click="detallesDespacho = true"
      >
        Imprimir
      </mdb-btn>
      <mdb-btn
        :disabled="deshabilitarBotonCargar"
        color="primario"
        icon="truck"
        class="px-3 py-2 order-sm-2"
        @click="$emit('cargar', identificador)"
      >
        Cargar
      </mdb-btn>
    </footer>
  </form>
  <DatosDespacho
    :modal-detalles-despacho="detallesDespacho"
    :vigilantes="vigilantes"
    :montacarguistas="montacarguistas"
    :almacenistas="almacenistas"
    :identificador="identificador"
    :nota-de-entrega="true"
    @cerrar="detallesDespacho = false"
  />
</section>
</template>

<script>
import { mdbBtn, mdbInput, mdbTooltip } from 'mdbvue'
import DatosDespacho from '@/components/DatosDespacho.vue'
import { convertirNumeroAFixed, formatearFechaYHora } from '@/funciones/funciones.js'
export default {
  name: 'TablaDePedidos',
  components: {
    mdbBtn,
    mdbInput,
    DatosDespacho,
    mdbTooltip
  },
  props: {
    datosPedidos: { // Datos de la tabla
      type: Object,
      default: () => {},
      required: true
    },
    identificador: {
      type: [String, Number],
      required: true
    },
    permitirEliminar: { // Activa los botones de Eliminar Cliente y Presentación
      type: Boolean,
      default: false
    },
    mostrarEncabezado: { // Activa el encabezado
      type: Boolean,
      default: false
    },
    titulo: {
      type: String,
      default: ''
    },
    // Para mostrar los filtros por categoría
    categorias: { // Lista de las categorías a filtrar
      type: Object,
      default: () => {}
    },
    mostrarFiltrosCategoria: { // Activa los filtros
      type: Boolean,
      default: false
    },
    cateMostrar: { // Distribucion categorias
      type: Array
    },
    // Importados para evitar errores visuales que desconozco su funcionalidad
    totalesPorPresentacionPedidos: {
      type: Object,
      default: () => {}
    },
    totalesPorPresentacion: {
      type: Object,
      default: () => {}
    },
    totalesPorPresentacionGeneral: {
      type: Object,
      default: () => {}
    },
    totalesPorPedido: {
      type: Object,
      default: () => {}
    },
    totalesPorPedidosPedido: {
      type: Object,
      default: () => {}
    },
    cargaPedido: {
      type: Object,
      default: () => {}
    },
    // Oculta los botones de acciones
    ocultarBotonesAcciones: {
      type: Boolean,
      default: false
    },
    deshabilitarBotonCargar: {
      type: Boolean,
      required: false
    },
    maximo: {
      type: Object,
      default: () => {}
    },
    // modal detalles despacho
    vigilantes: {
      type: Array,
      default: () => []
    },
    montacarguistas: {
      type: Array,
      default: () => []
    },
    almacenistas: {
      type: Array,
      default: () => []
    },
    nuevos: {
      type: Object,
      default: () => {
        return {
          porPed: {},
          porSuc: {}
        }
      }
    }
  },
  data () {
    return {
      detallesDespacho: false,
      cateAMostrar: ['1'],
      formatearFechaYHora
    }
  },
  computed: {
    pedidos () {
      return this.datosPedidos
    },
    categoriasAMostrar () {
      if (this.cateMostrar) return this.cateMostrar
      return this.cateAMostrar
    },
    totalPresentacionGeneral () {
      if (!this.totalesPorPresentacionGeneral || !Object.keys(this.totalesPorPresentacionGeneral).length) return {}
      const totalPre = {}
      for (const z in this.totalesPorPresentacionGeneral) {
        for (const p in this.totalesPorPresentacionGeneral[z]) {
          if (Number(p)) {
            if (!totalPre[p]) {
              totalPre[p] = 0
            }
            totalPre[p] += this.totalesPorPresentacionGeneral[z][p].cantidad
          }
        }
      }
      return totalPre
    }
  },
  methods: {
    convertirValorAFixed (numero) {
      return convertirNumeroAFixed(numero)
    },
    imprimirTabla () {
      window.print()
    },
    maximoInv (ped, pre, inv) {
      if (this.maximo && this.maximo[pre]) {
        if (this.totalesPorPresentacion[pre]) {
          const max = Number(this.maximo[pre]) + Number(inv) - this.totalesPorPresentacion[pre].cantidad
          return max < 0 ? 0 : max + Number(ped)
        } else {
          return Number(this.maximo[pre]) + Number(inv)
        }
      } else {
        if (this.totalesPorPresentacion[pre]) {
          const max = Number(inv) - this.totalesPorPresentacion[pre].cantidad
          return max < 0 ? 0 : max + Number(ped)
        } else {
          return Number(inv)
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
$tabla-separador: rgba(0, 0, 0, 0.05);

.tabla-pedidos { // Contenedor principal
  border: 1px solid $tabla-separador;
  border-radius: 6px;
  padding-bottom: .15rem;

  .encabezado-tabla,
  footer {
    align-items: center;
    display: flex;
    left: 0;
  }

  // Encabezado del título y filtros de la tabla
  .encabezado-tabla {
    flex-flow: row wrap;
  }

  header[class*="titulo"] {
    font-size: 135%;
    text-align: center;
    text-transform: uppercase;
    padding: .75rem 0 .75rem 1rem;

    @media screen and (min-width: 768px) {
      text-align: left;
    }
  }

  // Footer de la tabla
  footer {
    border-top: 1px solid $tabla-separador;
    flex-flow: row wrap;
    justify-content: center;
    padding: .75rem 0;
  }

  // Encabezados
  [class*="encabezado"] {
    color: #616161;
    font-size: .75rem;
    line-height: 1.3;
    text-transform: uppercase;
  }

  // Celda numerica
  .celda-numerica {
    font-size: .8rem;
    text-align: right;
  }

  // Negritas de 600
  .bold {font-weight: 600;}

  .bg-light-gray {
    @include bg-color-type-2 (#949494);
  }
}

// Tabla de distribución Zonas con flexbox
.tabla-contenedor { // Contenedor tabla
  overflow-x: auto;
  position: relative;
  width: 100%;
}

.tabla-contenedor {
  // Estilos a etiquetas generales
  small {display: block;}
  label {margin-bottom: 0;}

  // Estilizando las líneas divisorias horizontales
  & > div:not(:first-of-type),
  .tbody > .row-nowrap:not(:first-child),
  .productos > .row-nowrap:not(:first-child),
  .presentaciones > .row-nowrap:not(:first-child) {
    border-top: 1px solid $tabla-separador;
  }

  // Clases sticky para los encabezados
  [class*="sticky"] {
    background-color: #fff;
    position: sticky;
    z-index:1
  }

  .tabla-distribucion {
    min-width: 100%;
    width: max-content;

    .column-center {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    // Filas flex row nowrap
    .thead,
    .row-nowrap {
      display: flex;
      flex-flow: row nowrap;
    }
    .filas > div {
      font-size: .8rem;
      line-height: 1.3;
      padding: 0 .5rem;
      width: 162px;
    }

    .thead {
      background: #fff;
      border-bottom: 2px solid $tabla-separador;
      position: sticky;
      top: 0;
      z-index: 2;
    }

    .tbody {
      &> div:first-child {
        font-size: .8rem;
        min-width: max-content;
        padding: 0;
      }
      & > .row-nowrap.w-100 {
        @include bg-color-type-2 (#949494);
      }
    }

    .pedido {
      padding: 0 .5rem;
      width: 162px;
    }

    // Rotar titulo
    .rotar-titulo {
      font-size: .66rem;
      padding: .2rem;
      transform: scale(-1, -1);
      writing-mode: vertical-rl;
    }

    .columna-principal header.encabezado.bold {
      padding: 0.25rem 1rem;
    }
  }

  // Anchos de columna
  .ancho-1 {width: 244px;}
  .ancho-2 {width: 21px;}
  .ancho-2-2 {width: 100px;}
  .ancho-3 {width: 123px;}
  .ancho-4 {width: 150px;}
  .ancho-5 {width: 230px;}

  // Clases sticky para los encabezados
  .sticky-0 {left: 0px;}
  .sticky-1 {left: 21px;}
  .sticky-2 {left: 121px;}
  // Se usa para la columna Totales
  .left-0 {
    left: 0;
    position: sticky;
    z-index: 0;
  }

  // celda-checkbox
  .celda-checkbox {
    align-items: center;
    display: flex;
    font-size: .8rem;
    justify-content: space-between;

    p {
      font-size: inherit;
      line-height: 1.3;
      margin-bottom: 0;
    }

    .checkbox {
      align-items: center;
      cursor: pointer;
      display: flex;
      font-size: inherit;
      margin-left: .25rem;
      user-select: none;
      white-space: nowrap;
    }
    .checkbox input[type="checkbox"] {
      display: none;

      & + .icono-checkbox::before {
        @include agregar-icono('\f0c8', 400);
        color: $gris-tr-borde;
        font-size: 1.45rem;
        transition: color .5s ease;
      }
      &:checked + .icono-checkbox::before {
        @include agregar-icono('\f14a');
        color: $exitoso;
      }
    }
    & > .md-form {flex-grow: 2;}
  }
}

// Estableciendo tamaños fijos a la tabla
.tabla-pedidos.maxH-80 .tabla-contenedor {
  max-height: 80vh;
}

.fila-error {
  background-color: lighten($secundario, 46%);

  .texto-error {
    font-weight: bold;
  }
}
</style>
