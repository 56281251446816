<template>
  <section class="container pt-3">
      <!-- Mensaje para despacho sin clientes -->
      <div
        v-if="!Object.keys(despachos).length"
        class="mensaje-no-items pb-3"
      >
        <font-awesome-icon
          icon="calendar-times"
          size="5x"
          class="icono"
        />
        <p class="texto">No hay despachos para verificar</p>
      </div>
    <ul
      v-for="(despacho, iDespacho) in despachos"
      :key="'d'+iDespacho"
      class="table verificacion-cliente"
    >
      <li class="encabezado sticky">
        <div class="row align-items-center mx-0">
          <div class="col-12 col-md px-0">
            <header class="h4-responsive text-capitalize">
              Transportista {{ despacho.nom_per }} {{ despacho.ape_per }}
            </header>
            <p class="descripcion text-capitalize">
              Zonas:
              <span class="auto-coma" v-for="z in despacho.zonas" :key="'zn'+z.est_zon+iDespacho">
              {{z.est_zon}}
              </span>
            </p>
            <p class="descripcion">
              Clientes por verificar: {{cantidadClientes(despacho.zonas)}}
            </p>
          </div>
          <div class="col-12 col-md-auto pb-3 pb-md-0 text-center">
            <mdb-btn
              flat
              dark-waves
              icon="print"
              class="px-3 py-2 order-sm-3"
              @click="imprimirTablaDespacho(despacho)"
            >
              Imprimir
            </mdb-btn>
          </div>
        </div>
        <div class="thead claro">
          <div class="col-12 col-md-3 col-lg-2">
            Cliente - Sucursal
            <span class="small-block">RIF || SADA</span>
          </div>
          <div class="col-12 col-md col-xl-2">
            Tipo precio
            <span class="small-block">Tipo de cliente</span>
          </div>
          <div class="col-12 col-md col-xl-2">
            Condición de pago
            <span class="small-block">Promoción</span>
          </div>
          <div class="col">
            Teléfono
            <span class="small-block">Dirección</span>
          </div>
          <div class="col">Observación</div>
          <div class="col-md-2 col-lg-auto pr-md-3 pr-xl-4 acciones d-flex justify-content-end">Acción</div>
        </div>
      </li>
      <template v-for="zona in despacho.zonas">
        <li :key="'z'+zona.est_zon" class="contenido-subtitulo claro clearfix">
          <p class="py-2 px-2 d-inline-block">{{ zona.est_zon }}</p>
          <mdb-btn
            flat
            dark-waves
            icon="print"
            type="button"
            @click="imprimirTablaDespacho(despacho, zona)"
            class="my-0 py-1 px-2 float-right"
          >
            Imprimir esta zona
          </mdb-btn>
        </li>
        <li
          v-for="(cliente, idC) in zona.clientes"
          :key="'cl'+idC+cliente.id_suc+zona.est_zon+iDespacho"
          class="contenido"
        >
          <template v-if="!cliente.noMostrar">
            <div class="col-12 col-md-3 col-lg-2 clearfix" data-columna="Cliente - Sucursal / RIF || SADA">
              <p class="text-capitalize d-inline-block max-width-custom">
                {{ cliente.nom_emp }} - {{ cliente.nom_suc}}
                <span class="small-block font-md">
                  {{ cliente.rif_emp }}
                  {{ cliente.sada_suc ? `|| ${cliente.sada_suc}` : '' }}
                </span>
              </p>
              <span v-if="nuevos.porSuc[cliente.id_suc]" class="float-right ml-2 ml-lg-1 mt-2">
                <mdb-tooltip
                  trigger="hover"
                  :options="{placement: 'top'}"
                >
                  <span slot="tip" class="fs-initial">
                    <small class="bold">CLIENTE NUEVO</small>
                  </span>
                  <font-awesome-icon
                    icon="user-tie"
                    class="float-right"
                    slot="reference"
                  />
                </mdb-tooltip>
              </span>
            </div>
            <div
              class="col-12 col-md col-xl-2"
              data-columna="Tipo precio / Tipo de cliente"
            >
              <p>
                {{ cliente.nom_tpre }}
                <span class="small-block">{{ cliente.nom_tcl }}</span>
              </p>
            </div>
            <div
              class="col-12 col-md col-xl-2"
              data-columna="Condición de pago / Promoción"
            >
              <p>
                {{ cliente.nom_cp }}
                <span class="small-block">{{ cliente.dsc_ped }}%</span>
              </p>
            </div>
            <div class="col" data-columna="Teléfono / Dirección">
              <p>
                {{ cliente.tel_suc ? '+' + cliente.tel_suc.replace(/(\d{2})(\d{3})(\d{3})(\d{4})/, '$1 $2 $3 $4') : ''}}
                <span class="small-block">{{cliente.dir_suc}}</span>
              </p>
            </div>
            <div class="col" data-columna="Observación">
              <span :class="{'text-muted font-italic user-select-none': !cliente.obs_ped}"> {{ cliente.obs_ped ? cliente.obs_ped : 'Sin especificar'}} </span>
            </div>
            <div class="col-12 col-md-2 col-lg-auto py-md-0 acciones d-flex justify-content-md-end" data-columna="Acción">
              <mdb-btn
                flat
                dark-waves
                icon="user-times"
                size="sm"
                class="m-0 px-3"
                title="Eliminar cliente de la lista"
                @click="modalConfirmacionEliminar = true; obtenerEli=cliente; obtenerEli.id_des=despacho.id_des"
              >
                Eliminar
              </mdb-btn>
            </div>
          </template>
        </li>
      </template>
    </ul>
    <AlertaMensaje
      :alerta-mensaje="alertaMensaje"
      @cerrar="alertaMensaje.contenido = ''"
    />
    <ConfirmacionEliminar
      :boton-deshabilitado="botonDeshabilitado"
      icono-boton-confirmar="user-times"
      :mostrar-modal="modalConfirmacionEliminar"
      mensaje="El cliente será eliminado de la lista actual de despacho"
      @cerrar="modalConfirmacionEliminar = false"
      @confirmar="eliminarCliente()"
    />
    <OpcionesImpresion
      :mostrar-modal="opcionesDeImpresion.mostrarModal"
      :codigoDeImpresion="opcionesDeImpresion.codigoDeImpresion"
      :nombre-documento="opcionesDeImpresion.nombreDocumento"
      :orientacionSugerida="'landscape'"
      @cerrar="opcionesDeImpresion.mostrarModal = $event"
    />
  </section>
</template>

<script>
import { mdbBtn, mdbTooltip } from 'mdbvue'
import AlertaMensaje from '@/components/AlertaMensaje.vue'
import ConfirmacionEliminar from '@/components/ConfirmacionEliminar.vue'
import OpcionesImpresion from '@/components/OpcionesImpresion.vue'
import { VerificacionClienteTransportista } from '@/funciones/formatosImpresion.js'
import { apiPost } from '@/funciones/api.js'
import { squareRegular } from '@/imgsBase64/imgsBase64.js'

export default {
  name: 'VerificacionCliente',
  components: {
    mdbBtn,
    mdbTooltip,
    AlertaMensaje,
    ConfirmacionEliminar,
    OpcionesImpresion
  },
  props: {
    data: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      alertaMensaje: { contenido: '' },
      botonDeshabilitado: false,
      modalConfirmacionEliminar: false, // Modal para confirmar al eliminar
      opcionesDeImpresion: {
        codigoDeImpresion: [],
        mostrarModal: false,
        nombreDocumento: ''
      },
      obtenerEli: {},
      a: 1
    }
  },
  methods: {
    eliminarCliente () {
      apiPost({ s: 'desEliCliM' }, 'suc=' + this.obtenerEli.id_suc + '&des=' + this.obtenerEli.id_des)
        .then((res) => {
          if (res.data.r) {
            this.alertaMensaje = {
              contenido: 'Eliminado exitosamente',
              tipo: 'correcto'
            }
            this.modalConfirmacionEliminar = false
            this.obtenerEli.noMostrar = true
          } else {
            this.alertaMensaje = {
              contenido: 'No se ha eliminado',
              tipo: 'error'
            }
          }
        })
    },
    imprimirTablaDespacho (despacho, estado) {
      const TRANSPORTISTA = `${despacho.nom_per} ${despacho.ape_per}`
      // Estableciendo la estructura para la tabla de clientes
      const CLIENTES = [
        // Fila de encabezados
        [
          { text: '' },
          {
            stack: [
              'CLIENTE - SUCURSAL',
              {
                text: 'RIF | SADA',
                fontSize: 7
              },
              {
                text: 'TELÉFONO',
                fontSize: 7
              }
            ]
          },
          {
            stack: [
              'TIPO DE CLIENTE - TIPO DE PRECIO',
              {
                text: 'CONDICIÓN DE PAGO',
                fontSize: 7
              }
            ]
          },
          { text: 'PROMOCIÓN' },
          { text: 'DIRECCIÓN' },
          { text: 'OBSERVACIONES' }
        ]
      ]
      let zonas = ''

      if (estado) {
        zonas = estado.est_zon

        estado.clientes.forEach(cliente => {
          CLIENTES.push([
            { image: squareRegular },
            {
              stack: [
                `${cliente.nom_emp} - ${cliente.nom_suc}`,
                {
                  text: `RIF: ${cliente.rif_emp} ${cliente.sada_suc ? `| SADA: ${cliente.sada_suc}` : ''}`,
                  fontSize: 7
                },
                {
                  text: cliente.tel_suc ? `+${cliente.tel_suc.replace(/(\d{2})(\d{3})(\d{3})(\d{4})/, '$1 $2 $3 $4')}` : '',
                  fontSize: 7
                }
              ]
            },
            {
              stack: [
                `${cliente.nom_tcl} - ${cliente.nom_tpre}`,
                {
                  text: cliente.nom_cp,
                  fontSize: 8
                }
              ]
            },
            {
              text: `${cliente.dsc_ped}%`,
              fontSize: 8
            },
            { text: cliente.dir_suc },
            {
              stack: [
                {
                  text: `${this.nuevos.porSuc[cliente.id_suc] ? 'CLIENTE NUEVO' : ''}`,
                  fontSize: 8,
                  bold: true
                },
                cliente.obs_ped
              ]
            }
          ])
        })
      } else {
        let coma = ''
        // Estableciendo las zonas a string
        for (const idZon in despacho.zonas) {
          if (Object.hasOwnProperty.call(despacho.zonas, idZon)) {
            const zona = despacho.zonas[idZon]
            zonas += coma + zona.est_zon
            coma = ', '
          }
        }
        zonas += '.'

        for (const zona in despacho.zonas) {
          if (Object.hasOwnProperty.call(despacho.zonas, zona)) {
            const clientes = despacho.zonas[zona].clientes
            CLIENTES.push([
              {
                text: despacho.zonas[zona].est_zon,
                colSpan: 6,
                alignment: 'center',
                bold: true
              }
            ])

            clientes.forEach(cliente => {
              CLIENTES.push([
                { image: squareRegular },
                {
                  stack: [
                    `${cliente.nom_emp} - ${cliente.nom_suc}`,
                    {
                      text: `RIF: ${cliente.rif_emp} ${cliente.sada_suc ? `| SADA: ${cliente.sada_suc}` : ''}`,
                      fontSize: 7
                    },
                    {
                      text: cliente.tel_suc ? `+${cliente.tel_suc.replace(/(\d{2})(\d{3})(\d{3})(\d{4})/, '$1 $2 $3 $4')}` : '',
                      fontSize: 7
                    }
                  ]
                },
                {
                  stack: [
                    `${cliente.nom_tcl} - ${cliente.nom_tpre}`,
                    {
                      text: cliente.nom_cp,
                      fontSize: 8
                    }
                  ]
                },
                {
                  text: `${cliente.dsc_ped}%`,
                  fontSize: 8
                },
                { text: cliente.dir_suc },
                {
                  stack: [
                    {
                      text: `${this.nuevos.porSuc[cliente.id_suc] ? 'CLIENTE NUEVO' : ''}`,
                      fontSize: 8,
                      bold: true
                    },
                    cliente.obs_ped
                  ]
                }
              ])
            })
          }
        }
      }

      const { nombre, formato } = VerificacionClienteTransportista(TRANSPORTISTA, zonas, CLIENTES, this.cantidadClientes(despacho.zonas))

      this.opcionesDeImpresion = {
        codigoDeImpresion: formato,
        nombreDocumento: nombre,
        mostrarModal: true
      }
    },
    cantidadClientes (zon) {
      let totalCli = 0
      for (const z in zon) {
        totalCli += zon[z].clientes.length
      }
      return totalCli
    }
  },
  computed: {
    despachos () {
      if (!this.data) return []
      const despachos = {}
      this.data.des.forEach(d => {
        if (!despachos[d.id_des]) {
          despachos[d.id_des] = {
            id_des: d.id_des,
            nom_per: d.nom_per,
            ape_per: d.ape_per,
            mar_veh: d.mar_veh,
            mod_veh: d.mod_veh,
            zonas: {}
          }
        }
      })
      this.data.cliv.forEach(c => {
        if (!despachos[c.id_des]) {
          despachos[c.id_des] = {
            id_des: c.id_des,
            zonas: {}
          }
        }
        if (!despachos[c.id_des].zonas[this.zona[c.id_zon_suc].est_zon]) {
          despachos[c.id_des].zonas[this.zona[c.id_zon_suc].est_zon] = {
            est_zon: this.zona[c.id_zon_suc].est_zon,
            clientes: []
          }
        }
        despachos[c.id_des].zonas[this.zona[c.id_zon_suc].est_zon].clientes.push({
          id_suc: c.id_suc,
          nom_suc: c.nom_suc,
          sada_suc: c.sada_suc,
          nom_emp: c.nom_emp,
          rif_emp: c.rif_emp,
          nom_tdoc: c.nom_tdoc,
          nom_cp: c.nom_cp,
          nom_tpre: c.nom_tpre,
          id_zon_suc: c.id_zon_suc,
          nom_tcl: c.nom_tcl,
          dsc_ped: Number(c.dsc_ped),
          dir_suc: c.dir_suc,
          tel_suc: c.tel_suc,
          obs_ped: c.obs_ped
        })
      })

      return despachos
    },
    zona () {
      if (!this.data) return []
      const zona = {}
      this.data.zon.forEach(z => {
        zona[z.id_zon] = z
      })

      return zona
    },
    nuevos () {
      const nuevos = {
        porPed: {},
        porSuc: {}
      }
      if (!this.data || !this.data.nuevos) return nuevos
      this.data.nuevos.forEach((n) => {
        nuevos.porPed[n.id_ped] = true
        nuevos.porSuc[n.id_suc_ped] = true
      })
      return nuevos
    }
  }
}
</script>

<style lang="scss" scoped>
// Tabla verificacion-cliente breakpoints
.table.verificacion-cliente {
  margin: 0 auto 3rem;

  // Define la altura del sticky
  .encabezado.sticky {
    top: 122px;

    // Por cambio de height en menú secundario
    @media screen and (min-width: 397px) {top: 88px;}
    @media screen and (min-width: 768px) {top: 57px;}
  }

  .contenido > [class*="col"] {line-height: 1.3;}

  // Define los breakpoints para el responsive de ul.table
  $breakpointToChange: "768px";
  @include ul-table-responsive-at ($breakpointToChange);

  .encabezado .thead > [class*="col"],
  .contenido > [class*="col"] {
    padding: 0.6rem 0.25rem;

    @media (min-width: 1200px) {
      padding: 0.6rem 0.5rem;
    }
  }

  // Anchos específicos de columnas
  @media screen and (min-width: $breakpointToChange) {
    .acciones {width: 140px;}
  }
}

.max-width-custom {
  max-width: 85%
}
</style>
