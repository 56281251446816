<template>
  <div class="pagina-distribucion">
    <nav class="menu-secundario d-flex flex-wrap justify-content-center justify-content-md-end">
      <div
        v-if="tabComponenteActivo === 'zonas' & !directo"
        class="col-12 col-sm-auto col-md boton-cargar order-1 order-md-0 justify-content-md-end align-items-center"
      >
        <mdb-btn
          color="primario"
          icon="truck"
          class="mx-0 my-2 my-sm-0 px-3"
          @click="cargar = true"
          :disabled="botonDeshabilitado"
        >
          Cargar
        </mdb-btn>
      </div>
      <ul class="col-12 col-md-auto tab-nav offset-lg-1 offset-xl-2">
        <li
          :class="{'tab-activa': tabComponenteActivo === 'zonas'}"
          @click="activarComponente('zonas')"
        >
          Zonas
        </li>
        <li
          :class="{'tab-activa': tabComponenteActivo === 'despachos'}"
          @click="activarComponente('despachos')"
        >
          Despachos
        </li>
        <li
          :class="{'tab-activa': tabComponenteActivo === 'verificacionCliente'}"
          @click="activarComponente('verificacionCliente')"
        >
          Verificación clientes
        </li>
      </ul>
      <ul class="col-12 col-sm col-md-auto botones-fijos">
        <li
          title="Haz clic para ver el inventario"
          :class="{'item-activo': mostrarInventario}"
          @click="mostrarInventario = true"
        >
          <font-awesome-icon
            icon="boxes"
            size="lg"
          />
          <header>Inventario</header>
        </li>
        <li
          :class="[{'item-activo': mostrarFiltros}, {'invisible': !(tabComponenteActivo === 'zonas')}]"
          @click="mostrarFiltros = !mostrarFiltros, ScrollToTop()"
        >
          <font-awesome-icon
            icon="map-marked-alt"
            size="lg"
          />
          <header>Filtrar pedidos</header>
        </li>
      </ul>
    </nav>
    <transition
      mode="out-in"
      name="tabActiva-transicion"
    >
      <Despachos
        v-if="tabComponenteActivo === 'despachos'"
        :data="resultados.data"
        key="despachos"
        @actualizar="cargaInicial()"
      />
      <VerificacionCliente
        v-if="tabComponenteActivo === 'verificacionCliente'"
        :data="resultados.data"
        key="verificacionCliente"
      />
      <Zonas
        v-if="tabComponenteActivo === 'zonas'"
        key="zonas"
        :mostrar-filtros="mostrarFiltros"
        :data="resultados.data"
        :boton-cargar="cargar"
        @boton-deshabilitado="botonDeshabilitado = $event"
        @directo="directo = $event"
        @boton-cargado="cargar = $event"
        @ocultar-filtros="mostrarFiltros = !mostrarFiltros"
        @actualizar="cargaInicial()"
      />
    </transition>
    <mdb-modal
      centered
      elegant
      scrollable
      size="lg"
      :show="mostrarInventario"
      @close="mostrarInventario = false"
    >
      <mdb-modal-header>
        <mdb-modal-title>Inventario</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body class="p-0">
        <div v-if="Object.keys(categorias).length">
          <ul
            v-for="(cat, Catindex) in categorias"
            :key="Catindex"
            class="table inventario"
          >
            <li class="encabezado sticky">
              <div class="d-flex align-items-center">
                <div class="col user-select-none">
                  <header class="h4-responsive text-capitalize">
                    {{ Catindex }}
                  </header>
                </div>
              </div>
              <div class="thead claro">
                <div class="col">
                  Producto - Descripción
                  <span class="small-block">Código - Categoría</span>
                </div>
                <div class="col-12 col-sm-3 col-lg-2 text-right">
                  Existente
                  <span class="small-block">uni - paq</span>
                </div>
                <div class="col-12 col-sm-2 text-right">
                  Pedido
                  <span class="small-block">Peso</span>
                </div>
                <div class="col-12 col-sm-2 text-right">
                  Saldo
                  <span class="small-block">Peso</span>
                </div>
              </div>
            </li>
            <li
              v-for="(data, i) in cat"
              :key="i"
              :class="[{'fila-error' : (data.can_pre - data.can_np) < 0},'contenido hover-claro']"
            >
              <div class="col pr-1" data-columna="Producto - Descripción / Código - Categoría">
                <p>
                  {{ data.nom_pro }}-
                  <span class="text-uppercase">{{ data.des_pre }}</span>
                  <span class="small-block">
                    {{ data.cod_pre }} -
                    {{ data.nom_cat }}
                  </span>
                </p>
              </div>
              <div class="col-12 col-sm-3 col-lg-2 pl-1 celda-numerica" data-columna="Existente / Uni - Paq">
                <p>
                  {{data.can_pre}}
                  <span class="small-block text-capitalize">
                    {{data.uni_paq_pre}} - {{ data.paq_pre }} - {{Number(data.peso_exi.toFixed(2))}} kg
                  </span>
                </p>
              </div>
              <div class="col-12 col-sm-2 celda-numerica" data-columna="Pedido / Peso">
                <p>
                  {{ data.can_np }}
                  <span class="small-block">{{Number(data.peso_ped.toFixed(2))}} kg</span>
                </p>
              </div>
              <div :class="[{'texto-error' : (data.can_pre - data.can_np) < 0},'col-12 col-sm-2 celda-numerica']" data-columna="Saldo / Peso">
                <p>
                  {{ data.can_pre - data.can_np }}
                  <span class="small-block">{{Number(Number(data.peso_exi - data.peso_ped).toFixed(2))}} kg</span>
                </p>
              </div>
            </li>
            <template v-for="(totales, Tindex) in total">
              <li
                :key="Tindex"
                v-if="Tindex === Catindex"
                class="contenido claro hover-claro font-weight-bold"
              >
                <div class="col" data-columna="Totales">
                  <span class="d-none d-sm-inline text-uppercase">Totales</span>
                </div>
                <div class="col-12 col-sm-3 text-right">
                  {{totales.exs}}
                  <span class="small-block">{{Number(totales.peso_exs.toFixed(2))}} kg</span>
                </div>
                <div class="col-12 col-sm-2 text-right">
                  {{totales.pedido}}
                  <span class="small-block">{{Number(totales.peso.toFixed(2))}} kg</span>
                </div>
                <div class="col-12 col-sm-2 text-right">
                  {{totales.saldo}}
                  <span class="small-block">{{Number(totales.peso_saldo.toFixed(2))}} kg</span>
                </div>
              </li>
            </template>
          </ul>
        </div>
        <div v-else class="mensaje-no-items pb-3">
          <font-awesome-icon
            icon="boxes"
            size="5x"
            class="icono"
          />
          <p class="texto">Sin inventario disponible</p>
        </div>
      </mdb-modal-body>
    </mdb-modal>
  </div>
</template>

<script>
// @ is an alias to /src
import { mdbBtn, mdbModal, mdbModalHeader, mdbModalTitle, mdbModalBody } from 'mdbvue'
import Despachos from '@/components/distribucion/Despachos.vue'
import VerificacionCliente from '@/components/distribucion/VerificacionCliente.vue'
import Zonas from '@/components/distribucion/Zonas.vue'
import { apiPost } from '@/funciones/api.js'
import { convertirNumeroAFixed } from '@/funciones/funciones.js'

export default {
  name: 'Distribucion',
  components: {
    mdbBtn,
    mdbModal,
    mdbModalHeader,
    mdbModalTitle,
    mdbModalBody,
    Despachos,
    VerificacionCliente,
    Zonas
  },
  data () {
    return {
      resultados: {},
      tabComponenteActivo: 'zonas', // Indica el componente activo
      mostrarInventario: false, // Modal Inventario
      mostrarVendedores: false, // Sidebar con zonas
      zonas: [],
      mostrarFiltros: false,
      cargar: false,
      directo: true,
      botonDeshabilitado: false,
      inventario: [],
      total: {}
    }
  },
  computed: {
    categorias () {
      if (!this.inventario) return []
      const cate = {}
      this.inventario.forEach((inv) => {
        if (!cate[inv.nom_cat]) {
          cate[inv.nom_cat] = {}
          this.total[inv.nom_cat] = {
            exs: 0,
            pedido: 0,
            peso: 0,
            saldo: 0,
            peso_saldo: 0,
            peso_exs: 0
          }
        }
        cate[inv.nom_cat][inv.id_pre] = inv
        cate[inv.nom_cat][inv.id_pre].peso_ped = convertirNumeroAFixed(inv.pesm_pre * inv.can_np)
        cate[inv.nom_cat][inv.id_pre].peso_exi = convertirNumeroAFixed(inv.pesm_pre * inv.can_pre)
        this.total[inv.nom_cat].saldo = this.total[inv.nom_cat].saldo + Number(inv.can_pre - inv.can_np)
        this.total[inv.nom_cat].pedido = this.total[inv.nom_cat].pedido + Number(inv.can_np)
        this.total[inv.nom_cat].peso = this.total[inv.nom_cat].peso + Number(inv.peso_ped)
        this.total[inv.nom_cat].exs = this.total[inv.nom_cat].exs + Number(inv.can_pre)
        this.total[inv.nom_cat].peso_saldo = this.total[inv.nom_cat].peso_saldo + Number(inv.peso_exi - inv.peso_ped)
        this.total[inv.nom_cat].peso_exs = this.total[inv.nom_cat].peso_exs + Number(inv.peso_exi)
      })
      return cate
    }
  },
  methods: {
    ScrollToTop () {
      if (this.mostrarFiltros) {
        window.scrollTo(0, 0)
      }
    },
    cargaInicial () {
      this.resultados = {}
      apiPost({ s: 'disMv' }, '')
        .then((res) => {
          if (res.data) {
            this.resultados = res
            this.inventario = res.data.inv
          }
        })
    },
    activarComponente (componente) {
      this.tabComponenteActivo = componente === this.tabComponenteActivo
        ? 'zonas' : componente
    }
  },
  mounted () {
    this.cargaInicial()
  }
}
</script>
<style lang="scss" scoped>
.pagina-distribucion {
  .menu-secundario {
    background-color: $terciario;
    margin: 0 auto;
    max-width: 1140px;
    padding: .2rem .25rem .1rem 4.25rem;
    position: sticky;
    top: 0;
    z-index: 3;

    @media screen and (min-width: 768px) {
      align-items: center;
      display: flex;
      flex-direction: row-reverse;
      justify-content: flex-end;
    }
    @media screen and (min-width: 992px) {padding-left: .25rem;}

    ul {
      padding-inline-end: 0;
      align-items: center;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      @include reset-listas;

      li {
        cursor: pointer;
        font-size: .9rem;
        text-align: center;
        user-select: none;
      }
    }

    .tab-nav {
      li {
        border-bottom: 2px solid transparent;
        margin: .15rem 1rem;
        padding: .25rem 0 .05rem;
        transition-property: border-bottom-color;
        transition-duration: .5s;
        transition-timing-function: ease;

        &.tab-activa {border-bottom-color: $primario;}
        &:hover {border-bottom-color: $secundario;}
      }
      // Por cambio de height en menú secundario
      @media screen and (min-width: 397px) {
        li:nth-child(3) {order: 1;}
        li:nth-child(2) {order: 2;}
      }
    }

    .botones-fijos {
      margin-left: -4rem;

      // Por cambio de height en menú secundario
      @media screen and (min-width: 397px) {margin-left: -2rem;}
      @media screen and (min-width: 576px) {margin-left: 0;}

      li {
        border-radius: .25rem;
        margin: 0 .5rem;
        padding: .5rem;
        text-align: center;
        width: 90px;

        &:hover,
        &.item-activo {
          @include bg-color-type-1 ($secundario);
        }

        header {
          font-size: .7rem;
          white-space: nowrap;
        }
      }
    }
    .boton-cargar {
      display: flex;
      justify-content: center;
      margin-left: -4rem;
      @media screen and (min-width: 397px) {margin-left: -2rem;}
      @media screen and (min-width: 576px) {margin-left: 0;}
    }
  }
}
.fila-error {
  background-color: lighten($secundario, 48%);

  .texto-error {
    font-weight: bold;
  }
}
.table.inventario {
  small.block {display: block;}
  // Define los breakpoints para el responsive de ul.table
  @include ul-table-responsive-at ("576px");
}
</style>
